<template>
  <b-button
    v-bind="$attrs"
    :class="`${variantConvert} btn-loading`"
    :variant="variantConvert"
    :disabled="loading || disabled"
    :style="{cursor: disabled ? 'not-allowed': ''}"
    v-on="$listeners"
  >
    <b-spinner
      v-if="loading"
      small
    />
    <span
      v-else
      :id="spanId"
      class="button__label"
    >
      <slot />
    </span>
  </b-button>
</template>
<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import envMixin from '@/mixins/envMixin'

export default {
  components: {
    BButton,
    BSpinner,
  },

  mixins: [envMixin],

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    spanId: {
      type: String,
      required: false,
      default: '',
    },
    variantConvert: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>
<style lang="scss" scoped>
.btn-loading {
  border-radius: var(--btn-border-radius-base) !important;
}

.btn-submit, .btn-back {
  border-radius: var(--btn-border-radius-base) !important;
  padding: 11px 20px;
  font-size: 14px;
  font-weight: 600;
}

.btn-submit {
  color: #fff;
  background: linear-gradient(128.33deg, var(--button-color-start) -29.89%, var(--button-color-end) 98.77%);
  border: none;

  &:hover {
    color: #fff !important;
    box-shadow: 0 8px 25px -8px var(--btn-submit-box-shadow) !important;
  }
}

.btn-back {
  color: rgba(0, 0, 0, 0.70);
  background: var(--btn-back-color);
  border: 1px solid rgba(22, 33, 62, .7);

  &:hover {
    box-shadow: 0 8px 25px -8px var(--btn-back-box-shadow) !important;
  }
}

.btn-submit-outline {
  font-size: 14px;
  padding: 10px 14px;
  background: transparent;
  background-image: linear-gradient(90deg, white, white),
    linear-gradient(128deg, var(--button-color-start) -29.89%, var(--button-color-end) 98.77%);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: 1px solid transparent;

  .button__icon {
    color: var(--button-color-icon);
  }

  .button__label {
    background-image: linear-gradient(128deg, var(--button-color-start) -29.89%, var(--button-color-end) 98.77%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }

  &:hover {
    box-shadow: 0 8px 25px -8px var(--btn-submit-box-shadow) !important;
  }
}
</style>

<template>
  <b-modal
    ref="warning-redirect-modal"
    hide-footer
    hide-header
    hide-header-close
    centered
    no-close-on-backdrop
  >
    <div class="warning-container">
      <div class="warning-content text-center">
        <div class="d-flex align-items-center mb-1 justify-content-center">
          <b-img
            :src="require('@/assets/images/common/setting-warning.png')"
            alt="logo"
          />
        </div>
        <div>
          <b-card-text>
            We are happy for you to continue using our service. Please click button to be navigated to the full page and continue to use the service most conveniently.
          </b-card-text>
        </div>

        <btn-loading
          class="mt-2"
          variant-convert="btn-submit"
          @click="handelRedirectFullPage"
        >
          Continue
        </btn-loading>
      </div>
    </div>
  </b-modal>
</template>
<script>
import {
  BModal, BCardText, BImg,
} from 'bootstrap-vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

export default {
  components: {
    BtnLoading,
    BModal,
    BCardText,
    BImg,
  },

  methods: {
    hideModal() {
      this.$refs['warning-redirect-modal'].hide()
    },

    showModal() {
      this.$refs['warning-redirect-modal'].show()
    },

    handelRedirectFullPage() {
      const idToken = localStorage.getItem('id_token')
      window.open(`${process.env.VUE_APP_SITE_URL}?id_token=${idToken}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.warning-container{
  margin: 24px 0;

  .warning-content{
    max-width: 709px;
    width: 100%;

    border-radius: 20px;

    padding: 16px;
    margin: 0 auto;

    .text-title{
      margin:0 0 0 15px;

      font-size: 22px;
    }
  }
}
</style>

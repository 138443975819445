import auth from '@auth'
import { DEFAULT_STATE } from '@/constants'
import mutations from './mutations'
// eslint-disable-next-line import/no-cycle
import actions from './actions'
import getters from './getters'

const idTokenData = auth.getToken()

export default {
  namespaced: true,
  state: {
    ...DEFAULT_STATE,
    idToken: idTokenData,
    isLoggedIn: !!idTokenData,
    user: {
      loading: false,
      error: null,
      status: false,
      data: {},
    },
    userReports: {
      loading: false,
      error: null,
      status: false,
      data: {},
    },
    generalConfigs: {},
    plansStatus: {
      isTrialing: false,
      needToBeReviewed: false,
      outOfTrialAndNeedPlan: false,
      activePlan: null,
    },
    shopifyVerified: {},
    isLoginByShopify: false,

    isTikTokAppCenter: false,
    isAuthenticated: false,

    // data login by code
    dataAccessToken: idTokenData,
  },
  getters,
  mutations,
  actions,
}

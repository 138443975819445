<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component
      :is="layout"
    >
      <router-view />
    </component>
    <ads-blocked-notice-modal ref="open-ads-block-notice-modal" />
    <warning-redirect-full-page-modal ref="open-warning-redirect-modal" />
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
// import AppProfileAlert from '@core/components/app-alert-top-bar/AppProfileAlert.vue'
import UpdateTaxAlert from '@core/components/app-alert-top-bar/UpdateTaxAlert.vue'
import LiveChat from '@/components/LiveChat.vue'
import { createNamespacedHelpers } from 'vuex'
import get from 'lodash/get'
import { useWindowSize, useCssVar } from '@vueuse/core'
import store from '@/store'
import envMixin from '@/mixins/envMixin'
import { STYLES } from '@/constants'
import WarningRedirectFullPageModal from '@/components/WarningRedirectFullPageModal.vue'
import AdsBlockedNoticeModal from './components/AdsBlockedNoticeModal.vue'
import WarningMobileView from './components/WarningMobileView.vue'
import { loadThemeConfig } from './libs/common'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

const { mapGetters } = createNamespacedHelpers('auth')

export default {
  name: process.env.VUE_APP_OG_SITE_NAME,
  metaInfo: {
  // Children can override the title.
    title: process.env.VUE_APP_OG_TITLE,
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: process.env.VUE_APP_OG_TITLE,
    // Define meta tags here.
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: process.env.VUE_APP_OG_DESCRIPTION },

      // OpenGraph data (Most widely used)
      { property: 'og:title', content: process.env.VUE_APP_OG_TITLE },
      { property: 'og:site_name', content: process.env.VUE_APP_OG_SITE_NAME },
      // The list of types is available here: http://ogp.me/#types
      { property: 'og:type', content: 'website' },
      // Should the the same as your canonical link, see below.
      { property: 'og:url', content: process.env.VUE_APP_SITE_URL },
      { property: 'og:image', content: `${process.env.VUE_APP_SITE_URL}/share_rect.png` },
      // Often the same as your meta description, but not always.
      { property: 'og:description', content: process.env.VUE_APP_OG_DESCRIPTION },

      // Twitter card
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:site', content: `${process.env.VUE_APP_SITE_URL}` },
      { name: 'twitter:title', content: process.env.VUE_APP_OG_TITLE },
      { name: 'twitter:description', content: process.env.VUE_APP_OG_DESCRIPTION },
      // Your twitter handle, if you have one.
      { name: 'twitter:creator', content: '@alligatorio' },
      { name: 'twitter:image:src', content: `${process.env.VUE_APP_SITE_URL}/share_square.png` },

      // Google / Schema.org markup:
      { itemprop: 'name', content: process.env.VUE_APP_OG_TITLE },
      { itemprop: 'description', content: process.env.VUE_APP_OG_DESCRIPTION },
      { itemprop: 'image', content: `${process.env.VUE_APP_SITE_URL}/share_square.png` },
    ],
  },
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    // AppProfileAlert,
    UpdateTaxAlert,
    LiveChat,
    AdsBlockedNoticeModal,
    WarningMobileView,
    WarningRedirectFullPageModal,
  },
  mixins: [envMixin],
  data() {
    return {
      isMobile: false,
    }
  },

  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    ...mapGetters(['user', 'isTikTokAppCenter']),
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },

    contactInfo() {
      return get(this.user, ['data', 'contactInfo'], null)
    },

    businessModel() {
      return get(this.user, ['data', 'businessModel'], null)
    },

    showProfileAlert() {
      return !!((!this.contactInfo || !this.businessModel) && this.$route.meta.layout !== 'full' && this.$route.name !== 'profile')
    },

    // showTaxAlert() {
    //   return (this.$route.meta.layout !== 'full')
    // },

  },

  watch: {
    /* ---- Open if needed ----*/
    user: {
      handler(val) {
        // if (val?.data?.alert?.hasBanedBC) {
        //   this.$refs['open-ads-block-notice-modal'].showModal()
        // }

        const idToken = localStorage.getItem('id_token')
        if (this.isTikTokAppCenter && idToken && !!val?.data?.registerFromService && val?.data?.registerFromService !== 'TTAC') {
          this.$refs['open-warning-redirect-modal'].showModal()
        }
      },
      deep: true,
      immediate: true,
    },
  },

  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },

  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
    this.initFontFamily()
    this.initAppStyle()
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 1024
    },
    initFontFamily() {
      const { body } = document
      if (body && this.isEmediaPlatform) {
        body.classList.add('roboto-regular')
      }
    },

    initAppStyle() {
      if (this.isEmediaPlatform) {
        loadThemeConfig(STYLES.EMEDIA)
      }
    },
  },
}
</script>
<style scoped lang="scss">
  .mt-alert-bar{
    margin-top: 40px;
  }
</style>

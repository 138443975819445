import { DEFAULT_GETTERS } from '@/constants'

export default {
  ...DEFAULT_GETTERS,
  listAccountHistory: state => state.listAccountHistory,
  listBusinessAccountHistory: state => state.listBusinessAccountHistory,
  listBillingHistory: state => state.listBillingHistory,
  listCard: state => state.listCard,
  payoneerAccount: state => state.payoneerAccount,
  errorPayment: state => state.errorPayment,
  listBusinessAccountHistoryLimit: state => state.listBusinessAccountHistoryLimit,
  listTicket: state => state.listTicket,
  airwallexOptions: state => state.airwallexOptions,
  tazapayPending: state => state.tazapayPending,
  lianlianPending: state => state.lianlianPending,
  listLocalBank: state => state.listLocalBank,
  listRecentNotification: state => state.listRecentNotification,
  payFast: state => state.payFast,
  listRefundUserHistory: state => state.listRefundUserHistory,
}

import { AuthService } from '@/services'
import auth from '@auth'
// eslint-disable-next-line import/no-cycle
import { getResponse } from '@/plugins/store'
/* eslint-disable import/no-cycle */
import store from '@/store'
import { appCenter } from '@/router'
import { loadThemeConfig } from '@/libs/common'
import { STYLES } from '@/constants'

export default {
  async login({ commit }, { params }) {
    commit('LOGIN_REQUEST')
    try {
      const { data } = await AuthService.login(params)
      auth.setToken(getResponse(data).id_token)
      commit('LOGIN_SUCCESS', getResponse(data).id_token)
    } catch (error) {
      commit('LOGIN_FAILURE', error)
    }
  },
  async register({ commit }, { params }) {
    commit('REGISTER_REQUEST')
    try {
      await AuthService.register(params)
      commit('REGISTER_SUCCESS')
    } catch (error) {
      commit('REGISTER_FAILURE', error)
    }
  },
  async getAccountInfo({ commit }) {
    commit('GET_ACCOUNT_INFO_REQUEST')
    try {
      const { data } = await AuthService.getAccountInfo()
      store.commit('adsAccount/SET_TIKTOK_ACCOUNT_CURRENT', getResponse(data) || {})
      commit('GET_ACCOUNT_INFO_SUCCESS', getResponse(data) || {})
      localStorage.setItem('userData', JSON.stringify(getResponse(data)))
    } catch (error) {
      commit('GET_ACCOUNT_INFO_FAILURE', error)
    }
  },
  async getAccountReport({ commit }) {
    commit('GET_ACCOUNT_REPORT_REQUEST')
    try {
      const { data } = await AuthService.getAccountReport()
      commit('GET_ACCOUNT_REPORT_SUCCESS', getResponse(data) || {})
    } catch (error) {
      commit('GET_ACCOUNT_REPORT_FAILURE', error)
    }
  },
  async resetInit({ commit }, params) {
    commit('RESET_INIT_REQUEST')
    try {
      await AuthService.resetInit(params)
      commit('RESET_INIT_SUCCESS')
    } catch (error) {
      commit('RESET_INIT_FAILURE', error)
    }
  },
  async resetFinish({ commit }, { params }) {
    commit('RESET_FINISH_REQUEST')
    try {
      await AuthService.resetFinish(params)
      commit('RESET_FINISH_SUCCESS')
    } catch (error) {
      commit('RESET_FINISH_FAILURE', error)
    }
  },

  async getGeneralConfig({ commit }) {
    commit('GET_GENERAL_CONFIG_REQUEST')
    try {
      const { data } = await AuthService.getGeneralConfig()
      commit('GET_GENERAL_CONFIG_SUCCESS', getResponse(data) || {})
    } catch (error) {
      commit('GET_GENERAL_CONFIG_FAILURE', error)
    }
  },

  async updateAccount({ commit }, params) {
    commit('UPDATE_ACCOUNT_REQUEST')
    try {
      await AuthService.updateAccount(params)
      commit('UPDATE_ACCOUNT_SUCCESS')
    } catch (error) {
      commit('UPDATE_ACCOUNT_FAILURE', error)
    }
  },
  async updatePassword({ commit }, params) {
    commit('UPDATE_PASSWORD_REQUEST')
    try {
      await AuthService.updatePassword(params)
      commit('UPDATE_PASSWORD_SUCCESS')
    } catch (error) {
      commit('UPDATE_PASSWORD_FAILURE', error)
    }
  },

  async verifyShopifyConnect({ commit }, params) {
    commit('VERIFY_SHOPIFY_CONNECT_REQUEST')
    try {
      const { data } = await AuthService.verifyShopifyConnect(params)
      commit('VERIFY_SHOPIFY_CONNECT_SUCCESS', getResponse(data))
    } catch (error) {
      commit('VERIFY_SHOPIFY_CONNECT_FAILURE', error)
    }
  },

  async requestVerifyEmail({ commit }) {
    commit('REQUEST_VERIFY_EMAIL')
    try {
      await AuthService.requestVerifyEmail()
      commit('REQUEST_VERIFY_EMAIL_SUCCESS')
    } catch (error) {
      commit('REQUEST_VERIFY_EMAIL_FAILURE', error)
    }
  },

  async sendCodeVerifyEmail({ commit }, params) {
    commit('SEND_CODE_VERIFY_EMAIL')
    try {
      const { data } = await AuthService.sendCodeVerifyEmail(params)
      commit('SEND_CODE_VERIFY_EMAIL_SUCCESS', getResponse(data))
    } catch (error) {
      commit('SEND_CODE_VERIFY_EMAIL_FAILURE', error)
    }
  },

  async verifyEmailByCode({ commit }, params) {
    commit('VERIFY_EMAIL_BY_CODE')
    try {
      const { data } = await AuthService.verifyEmailByCode(params)
      commit('VERIFY_EMAIL_BY_CODE_SUCCESS', getResponse(data))
    } catch (error) {
      commit('VERIFY_EMAIL_BY_CODE_FAILURE', error)
    }
  },

  setIsTikTokAppCenter({ state }, isTikTokAppCenter) {
    state.isTikTokAppCenter = isTikTokAppCenter

    if (isTikTokAppCenter) {
      store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
      loadThemeConfig(STYLES.TIKTOK_APP_CENTER)
    }
  },

  setIsAuthenticated({ state }, isAuth) {
    state.isAuthenticated = isAuth
  },

  logout({ state }) {
    auth.clearLocalStorage()
    if (state.isTikTokAppCenter) {
      appCenter.redirectToAuth('MAPI')
    } else if (process.env.VUE_APP_LOGIN_URL) {
      window.location.href = `${process.env.VUE_APP_LOGIN_URL}/logout`
    } else {
      window.location.href = '/login'
    }
  },
}
